<template>
  <div
    class="toggle-arrow"
    @click.prevent="emitEvent"
    :class="{ 'open': status, 'closed': !status }"
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37">
      <g transform="rotate(180 18.5 18.5)">
        <path
          d="M18.5,0 C8.29776861,0 0,8.29892493 0,18.5 C0,28.7010751 8.29892493,37 18.5,37 C28.7022314,37 37,28.7010751 37,18.5 C37,8.29892493 28.7010751,0 18.5,0 Z M18.5,2.64335271 C27.2741734,2.64335271 34.3578036,9.72813926 34.3578036,18.5011563 C34.3578036,27.2741734 27.2741734,34.3589599 18.5,34.3589599 C9.72582661,34.3589599 2.64219639,27.2753297 2.64219639,18.5011563 C2.64219639,9.72698294 9.72582661,2.64335271 18.5,2.64335271 Z"
        />
        <path
          d="M0,1.58409387 C0,1.17731421 0.168376743,0.786179922 0.488292555,0.47327249 C1.14496185,-0.136897001 2.22257301,-0.136897001 2.8792423,0.47327249 L7.99789529,5.21382008 L13.1165483,0.457627119 C13.7732176,-0.152542373 14.8508287,-0.152542373 15.507498,0.457627119 C16.1641673,1.06779661 16.1641673,2.06910039 15.507498,2.67926988 L9.19337017,8.54628422 C8.87345435,8.84354628 8.4525125,9 7.99789529,9 C7.54327808,9 7.12233623,8.82790091 6.80242042,8.54628422 L0.488292555,2.69491525 C0.168376743,2.38200782 0,1.97522816 0,1.58409387 Z"
          transform="translate(11 15)"
        />
      </g>
    </svg>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  props: {
    section: String,
    status: Boolean
  },
  methods: {
    emitEvent() {
      this.$emit('click', this.section)
    }
  }
}
</script>

<style lang="scss">
.toggle-arrow {
  &.open {
    transform: rotate(180deg);
  }
  &.closed {
  }
}
</style>
